import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Api, Http } from 'src/api';
import { authQueries, useProfileQuery } from 'src/api/queries';
import { AuthLoginRequest, AuthLoginResponse } from 'src/api/services/AuthClient';
import { Broadcast } from 'src/broadcast';
import { IntercomObj } from 'src/services/Intercom';
import { pushNotification } from 'src/lib/services/push-notification';
import { joinExistingValues } from 'src/helpers/joinExistingValues';
import { JsonWrapper } from '../JsonWrapper';
import { AuthJwtObject, jwtStorageKey } from './Auth.types';
import { AuthUtils } from './Auth.utils';
import { isAuthJwtObject } from './Auth.guards';
import { appRoutes } from 'src/routes/routes.types';
import { useBreakpoint } from 'src/lib/hooks';
import { usePreLoginAuthLocation } from 'src/routes/use-pre-login-auth-location';

let timeoutId: NodeJS.Timeout | null = null;

const useAuth = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();
  const { getLocation } = usePreLoginAuthLocation();
  const location = useLocation();

  const query = useProfileQuery({ enabled: false });

  const isLoaded = query.isSuccess || query.isError,
    isLoading = query.isLoading,
    isInitialLoading = query.isInitialLoading;

  const startSession = async (jwt: string | AuthJwtObject) => {
    const token = isAuthJwtObject(jwt) ? jwt : AuthUtils.make(jwt);

    AuthUtils.setJwt(token);
    Http.setBearer(token.token);
    if (document.visibilityState !== 'hidden') {
      Broadcast.user.connect(token.token);
    }
    IntercomObj.init();
    if (pushNotification.isSupported()) {
      pushNotification.init();
    }

    const data = await queryClient.fetchQuery({ queryKey: query.queryKey, queryFn: query.queryFn });

    IntercomObj.boot({
      name: joinExistingValues([data!.first_name, data!.last_name], ' '),
      email: data!.email,
      user_hash: data!.intercom_hmac,
      created_at: data!.created_at,
      type: data!.type,
      days_since_last_login: data!.days_since_last_login,
    });

    // Hide Intercom on mobile if there are no unread messages, until the user receives a new message
    if (isMobile) {
      IntercomObj.onUnreadCountChange((unreadCount) => {
        if (unreadCount === 0 && !window.intercomSettings.initial_unread_count_checked) {
          IntercomObj.hide();
        } else {
          IntercomObj.unhide();
        }

        window.intercomSettings.initial_unread_count_checked = true;
      });
    }
  };

  const endSession = async () => {
    await Promise.all(
      Object.values(authQueries).map(async (queryKey) => {
        await queryClient.cancelQueries({ queryKey: [queryKey] });
        queryClient.removeQueries({ queryKey: [queryKey] });
      }),
    );

    Http.setBearer('');
    Broadcast.user.disconnect();
    IntercomObj.hardShutdown();

    AuthUtils.removeJwt();
  };

  const login = async (data: AuthLoginRequest): Promise<AuthLoginResponse> => {
    const { access_token } = await Api.auth.login(data);

    await startSession(access_token);

    return { access_token };
  };

  const logout = async (): Promise<void> => {
    await Api.auth.logout();

    await endSession();
  };

  const refresh = async () => {
    if (AuthUtils.isExpired()) {
      AuthUtils.removeJwt();

      return;
    }

    await Api.auth
      .refresh()
      .then(async ({ access_token }) => {
        if (!access_token) {
          await endSession();

          return;
        }
        await startSession(access_token);
      })
      .catch(async () => await endSession());
  };

  const handleStorageTokenChange = async (e: StorageEvent): Promise<void> => {
    if (e.key !== jwtStorageKey) {
      return;
    }

    if (e.oldValue && !e.newValue) {
      // Means user has logged out
      await endSession();
      navigate(appRoutes.login);
      return;
    }

    const newJwt = JsonWrapper.toJson(e.newValue);
    if (!isAuthJwtObject(newJwt)) {
      return;
    }

    if (!e.oldValue) {
      // Means user has logged in
      await startSession(newJwt);
      navigate(getLocation(location.state?.from) ?? appRoutes.profile, {
        replace: true,
      });
      return;
    }

    if (e.oldValue && e.newValue) {
      // Means token has been refreshed, probably

      const oldJwt = JsonWrapper.toJson(e.oldValue);
      if (
        isAuthJwtObject(oldJwt) &&
        !AuthUtils.isExpired(newJwt) &&
        oldJwt.token !== newJwt.token
      ) {
        await startSession(newJwt);
      }
    }
  };

  const handleVisibilityChange = async () => {
    if (document.visibilityState === 'hidden') {
      timeoutId = setTimeout(() => {
        Broadcast.user.disconnect();
      }, 5000);

      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (!Broadcast.user.isConnected() && !AuthUtils.isExpired()) {
      Broadcast.user.connect(AuthUtils.getJwt().token);
      queryClient.invalidateQueries({
        queryKey: [authQueries.notifications],
      });
      queryClient.invalidateQueries({
        queryKey: [authQueries.unreadNotifications],
      });
      queryClient.invalidateQueries({
        queryKey: [authQueries.requestCounts],
      });
    }
  };

  const mount = () => {
    window.addEventListener('storage', handleStorageTokenChange);
    window.addEventListener('visibilitychange', handleVisibilityChange);
  };

  const dismount = () => {
    window.removeEventListener('storage', handleStorageTokenChange);
    window.removeEventListener('visibilitychange', handleVisibilityChange);
  };

  return {
    isLoaded,
    isLoading,
    isInitialLoading,
    login,
    logout,
    refresh,
    startSession,
    endSession,
    mount,
    dismount,
  };
};

export { useAuth };
