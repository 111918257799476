import React from 'react';
import { Modal, ModalBody, ModalProps } from 'reactstrap';
import { MinimalMediaCardUpload } from 'src/components/MediaCard';
import { useTranslation } from 'react-i18next';
import { useMediaUploadStore } from 'src/lib/services/media-upload-store';
import { notify } from 'src/components/Toast';
import { Form as FormikForm, Formik, FormikHelpers, useFormikContext } from 'formik';
import { DropzoneInputProps } from 'src/components/ui/form/dropzone-input';
import { If } from 'src/components/If';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { Button } from 'src/components/ui/button';
import { X } from 'lucide-react';
import DirtyFormPrompt from 'src/components/ui/dirty-form-prompt';
import {
  flattenMediaCollectionsErrors,
  mergeUploads,
} from 'src/pages/RequestPage/RequestStatusChangeForm/RequestStatusChangeForm.utils';
import { Label } from 'src/components/ui/label';
import Dropzone from 'react-dropzone';
import clsx from 'clsx';
import { ErrorMessage } from 'src/components/Form/ErrorMessage';
import { useGenerateMultipartUpload } from 'src/components/Form/S3UploadInput';
import { extractFormErrors } from 'src/components/Form/Form.utils';
import { UpdateRevisionRequest } from 'src/api/services/RevisionClient';

type FormValues = UpdateRevisionRequest;

type Props = Omit<ModalProps, 'onSubmit' | 'children' | 'toggle'> & {
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => Promise<void>;
  dropzone?: Omit<DropzoneInputProps, 'model' | 'children'>;
  toggle: () => void;
};

const EditRevisionFormModal: React.FC<Props> = ({ onSubmit, dropzone, toggle, ...props }) => {
  const { selectedRevision, updateRevisionMutation } = useRequestRevisionsStore();
  if (!props.isOpen) {
    return null;
  }

  const selectedRevisionMedia = selectedRevision?.raw?.media ?? [];
  const defaultMedia = selectedRevisionMedia.filter((m) => m.collection_name === 'default') ?? [];
  const projectFilesMedia =
    selectedRevisionMedia.filter((m) => m.collection_name === 'project_files') ?? [];
  const thumbnailsMedia =
    selectedRevisionMedia.filter((m) => m.collection_name === 'thumbnails') ?? [];
  const captionsMedia = selectedRevisionMedia.filter((m) => m.collection_name === 'captions') ?? [];
  const resizesMedia = selectedRevisionMedia.filter((m) => m.collection_name === 'resizes') ?? [];

  const initialFormValues = {
    media: {
      default: defaultMedia,
      project_files: projectFilesMedia,
      thumbnails: thumbnailsMedia,
      captions: captionsMedia,
      resizes: resizesMedia,
    },
  };

  return (
    <Formik<FormValues>
      initialValues={initialFormValues}
      onSubmit={async (values, helpers) => {
        if (!selectedRevision?.id) {
          return;
        }

        await updateRevisionMutation.mutateAsync([selectedRevision.id, values], {
          onError: (err) => {
            helpers.setErrors(flattenMediaCollectionsErrors(extractFormErrors(err)));
          },
        });

        if (onSubmit) {
          await onSubmit(values, helpers);
        }

        helpers.resetForm({
          values,
          isSubmitting: false,
          submitCount: 0,
        });

        toggle();
      }}
    >
      <EditRevisionFormContent toggle={toggle} {...props} />
    </Formik>
  );
};

type FormContentProps = Omit<React.ComponentProps<typeof Modal>, 'toggle'> & {
  toggle: () => void;
};

const EditRevisionFormContent: React.FC<FormContentProps> = ({ toggle, ...props }) => {
  const { t } = useTranslation();
  const { selectedRevision, updateRevisionMutation } = useRequestRevisionsStore();
  const { isUploading, isFailed } = useMediaUploadStore();
  const formik = useFormikContext<FormValues>();
  const { generateUpload } = useGenerateMultipartUpload();

  const isFinalFilesRevision = selectedRevision?.isFinalFiles;

  const uploads = mergeUploads(formik.values);
  const isUploadingMedia = uploads.some((m) => isUploading(m.id));
  const uploadsFailed = uploads.some((m) => isFailed(m.id));

  const toggleModal = () => {
    if (isUploadingMedia) {
      notify(t("Files haven't finished uploading"), {
        type: 'warning',
      });

      return;
    }

    if (!formik.dirty) {
      toggle();
      return;
    }

    if (confirm(t('Are you sure you want to close without saving?')!)) {
      toggle();
    }
  };

  const selectedRevisionMedia = selectedRevision?.raw?.media ?? [];

  const projectFiles = selectedRevisionMedia.filter((m) => m.collection_name === 'project_files');
  const defaultFiles = selectedRevisionMedia.filter((m) => m.collection_name === 'default');
  const resizesFiles = selectedRevisionMedia.filter((m) => m.collection_name === 'resizes');
  const captionsFiles = selectedRevisionMedia.filter((m) => m.collection_name === 'captions');
  const thumbnailsFiles = selectedRevisionMedia.filter((m) => m.collection_name === 'thumbnails');

  const initialFormValues = {
    media: {
      project_files: projectFiles,
      default: defaultFiles,
      resizes: resizesFiles,
      captions: captionsFiles,
      thumbnails: thumbnailsFiles,
    },
    is_final_files: !!isFinalFilesRevision,
  };

  return (
    <Modal
      toggle={toggleModal}
      onOpened={() => {
        formik.resetForm({
          values: initialFormValues,
        });
      }}
      {...props}
    >
      <div className={'tw-flex tw-items-center tw-justify-between tw-gap-4 tw-px-4 tw-pt-4'}>
        <p className={'tw-text-title-sm tw-font-medium'}>{t('Edit revision')}</p>
        <Button variant={'ghost'} size={'icon'} onClick={toggleModal}>
          <X className={'tw-size-5'} />
        </Button>
      </div>
      <ModalBody>
        <FormikForm className={'tw-flex tw-flex-col tw-gap-2 tw-rounded'}>
          <div className={'tw-flex tw-flex-col'}>
            <Label>{t('Main revision video')}</Label>
            <Dropzone
              multiple={true}
              disabled={formik.isSubmitting}
              onDrop={async (files) => {
                await generateUpload('request_revision', files, 'default').catch(() => ({}));
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className={clsx(
                    'tw-relative tw-flex tw-h-[6.3rem] tw-flex-col tw-rounded-md tw-border-2 tw-border-dashed tw-border-neutral-300',
                    getRootProps().className,
                  )}
                >
                  <If when={!formik.values.media.default.length}>
                    <h4 className={'tw-my-auto tw-self-center tw-text-center tw-text-muted'}>
                      {t('Click to browse or drag and drop files here')}
                    </h4>
                  </If>

                  <div
                    className={
                      'tw-absolute tw-inset-0 tw-flex tw-items-end tw-gap-2 tw-overflow-x-auto tw-p-2'
                    }
                  >
                    {formik.values.media.default.map((m) => (
                      <MinimalMediaCardUpload
                        key={m.id}
                        media={m}
                        canDownload={true}
                        displayName={true}
                        className={'tw-z-10 tw-w-full tw-shrink-0'}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    ))}
                    <input {...getInputProps()} />
                  </div>
                </div>
              )}
            </Dropzone>

            <ErrorMessage name={'media.default'} />
          </div>

          <div className={'tw-flex tw-flex-col'}>
            <Label>{t('Video editing project files')}</Label>
            <Dropzone
              multiple={true}
              disabled={formik.isSubmitting}
              onDrop={async (files) => {
                formik.setValues((prev) => ({
                  ...prev,
                  media: {
                    ...prev.media,
                    project_files: [],
                  },
                }));
                await generateUpload('request_revision', files, 'project_files').catch(() => ({}));
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className={clsx(
                    'tw-relative tw-flex tw-h-[6.3rem] tw-flex-col tw-rounded-md tw-border-2 tw-border-dashed tw-border-neutral-300',
                    getRootProps().className,
                  )}
                >
                  <If when={!formik.values.media.project_files?.length}>
                    <h4 className={'tw-my-auto tw-self-center tw-text-center tw-text-muted'}>
                      {t('Click to browse or drag and drop files here')}
                    </h4>
                  </If>

                  <div
                    className={
                      'tw-absolute tw-inset-0 tw-flex tw-items-end tw-gap-2 tw-overflow-x-auto tw-p-2'
                    }
                  >
                    {formik.values.media.project_files?.map((m) => (
                      <MinimalMediaCardUpload
                        key={m.id}
                        media={m}
                        canDownload={true}
                        displayName={true}
                        className={'tw-z-10 tw-w-full tw-shrink-0'}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    ))}
                    <input {...getInputProps()} />
                  </div>
                </div>
              )}
            </Dropzone>

            <ErrorMessage name={`media.project_files`} />
          </div>

          <div className={'tw-flex tw-flex-col'}>
            <Label>{t('Resizes and variations')}</Label>
            <Dropzone
              multiple={true}
              disabled={formik.isSubmitting}
              onDrop={async (files) => {
                await generateUpload('request_revision', files, 'resizes').catch(() => ({}));
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className={clsx(
                    'tw-relative tw-flex tw-h-[6.3rem] tw-flex-col tw-rounded-md tw-border-2 tw-border-dashed tw-border-neutral-300',
                    getRootProps().className,
                  )}
                >
                  <If when={!formik.values.media.resizes?.length}>
                    <h4 className={'tw-my-auto tw-self-center tw-text-center tw-text-muted'}>
                      {t('Click to browse or drag and drop files here')}
                    </h4>
                  </If>

                  <div
                    className={
                      'tw-absolute tw-inset-0 tw-flex tw-items-end tw-gap-2 tw-overflow-x-auto tw-p-2'
                    }
                  >
                    {formik.values.media.resizes?.map((m) => (
                      <MinimalMediaCardUpload
                        key={m.id}
                        media={m}
                        canDownload={true}
                        displayName={true}
                        className={'tw-z-10 tw-w-full tw-shrink-0'}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    ))}
                    <input {...getInputProps()} />
                  </div>
                </div>
              )}
            </Dropzone>

            <ErrorMessage name={`media.resizes`} />
          </div>

          <div className={'tw-flex tw-flex-col'}>
            <Label>{t('Thumbnail')}</Label>
            <Dropzone
              multiple={true}
              disabled={formik.isSubmitting}
              onDrop={async (files) => {
                await generateUpload('request_revision', files, 'thumbnails').catch(() => ({}));
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className={clsx(
                    'tw-relative tw-flex tw-h-[6.3rem] tw-flex-col tw-rounded-md tw-border-2 tw-border-dashed tw-border-neutral-300',
                    getRootProps().className,
                  )}
                >
                  <If when={!formik.values.media.thumbnails?.length}>
                    <h4 className={'tw-my-auto tw-self-center tw-text-center tw-text-muted'}>
                      {t('Click to browse or drag and drop files here')}
                    </h4>
                  </If>

                  <div
                    className={
                      'tw-absolute tw-inset-0 tw-flex tw-items-end tw-gap-2 tw-overflow-x-auto tw-p-2'
                    }
                  >
                    {formik.values.media.thumbnails?.map((m) => (
                      <MinimalMediaCardUpload
                        key={m.id}
                        media={m}
                        canDownload={true}
                        displayName={true}
                        className={'tw-z-10 tw-w-full tw-shrink-0'}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    ))}
                    <input {...getInputProps()} />
                  </div>
                </div>
              )}
            </Dropzone>

            <ErrorMessage name={`media.thumbnails`} />
          </div>

          <div className={'tw-flex tw-flex-col'}>
            <Label>{t('Captions')}</Label>
            <Dropzone
              multiple={true}
              disabled={formik.isSubmitting}
              onDrop={async (files) => {
                await generateUpload('request_revision', files, 'captions').catch(() => ({}));
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  className={clsx(
                    'tw-relative tw-flex tw-h-[6.3rem] tw-flex-col tw-rounded-md tw-border-2 tw-border-dashed tw-border-neutral-300',
                    getRootProps().className,
                  )}
                >
                  <If when={!formik.values.media.captions?.length}>
                    <h4 className={'tw-my-auto tw-self-center tw-text-center tw-text-muted'}>
                      {t('Click to browse or drag and drop files here')}
                    </h4>
                  </If>

                  <div
                    className={
                      'tw-absolute tw-inset-0 tw-flex tw-items-end tw-gap-2 tw-overflow-x-auto tw-p-2'
                    }
                  >
                    {formik.values.media.captions?.map((m) => (
                      <MinimalMediaCardUpload
                        key={m.id}
                        media={m}
                        canDownload={true}
                        displayName={true}
                        className={'tw-z-10 tw-w-full tw-shrink-0'}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    ))}
                    <input {...getInputProps()} />
                  </div>
                </div>
              )}
            </Dropzone>

            <ErrorMessage name={`media.captions`} />
          </div>

          <If when={uploadsFailed}>
            <span className="invalid-feedback tw-mt-2 tw-block tw-text-destructive">
              {t('Some files failed to upload, remove them and try again')}
            </span>
          </If>

          <div className={'tw-flex tw-flex-wrap tw-gap-2'} onClick={(e) => e.stopPropagation()}>
            <button
              disabled={formik.isSubmitting || isUploadingMedia}
              className={
                'btn btn-outline-primary tw-w-full tw-self-start tw-px-4 tw-py-2 md:tw-ms-auto md:tw-w-auto'
              }
              type={'button'}
              onClick={() => {
                formik.resetForm({
                  values: initialFormValues,
                  submitCount: 0,
                });
              }}
            >
              {t('Reset')}
            </button>

            <button
              disabled={formik.isSubmitting || isUploadingMedia || uploadsFailed}
              className={'btn btn-primary tw-w-full tw-self-end tw-px-4 tw-py-2 md:tw-w-auto'}
              type={'submit'}
            >
              {t('Submit')}
            </button>
          </div>

          <DirtyFormPrompt isDirty={formik.dirty} />
        </FormikForm>
      </ModalBody>
    </Modal>
  );
};

export { EditRevisionFormModal };
