import 'src/assets/scss/theme.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import { IntlProvider } from 'react-intl';
import ModalLoaderProvider from 'src/components/ModalLoader/Provider';
import AppRoutes from 'src/routes/app-routes';
import AppUpdateDialog from 'src/features/sw-update/app-update-dialog';

const App: React.FC = () => {
  return (
    <ModalLoaderProvider>
      <IntlProvider locale={'en'}>
        <AppUpdateDialog />
        <AppRoutes />
      </IntlProvider>
    </ModalLoaderProvider>
  );
};

export default App;
