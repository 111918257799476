import { lazy } from 'react';
import { appRoutes, AppRoutesObject, ViewRestricted } from './routes.types';
import {
  airtableRootRoute,
  airtableBaseRoute,
  airtableBaseTableRoute,
} from 'src/lib/router/airtable-routes';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { Navigate } from 'react-router-dom';

const NonAuthLayout = lazy(() => import('src/components/ui/layouts/non-auth-layout'));
const AuthenticatedLayout = lazy(
  () => import('src/components/ui/layouts/authenticated-layout/authenticated-layout'),
);

const restricted: { [key in ViewRestricted]: ViewRestricted } = {
  guest: 'guest',
  admin: 'admin',
  editor: 'editor',
};

const RootPage = () => {
  const isLogged = !AuthUtils.isExpired();

  if (!isLogged) {
    return <Navigate to={appRoutes.login} replace={true} />;
  }

  return <Navigate to={appRoutes.profile} replace={true} />;
};

const routes: AppRoutesObject = {
  root: {
    path: appRoutes.root,
    element: <RootPage />,
    layout: NonAuthLayout,
    index: true,
  },
  notFound: {
    path: appRoutes.notFound,
    lazy: async () => {
      const { NotFoundPage } = await import('src/pages/NotFoundPage/NotFoundPage');
      return { Component: NotFoundPage };
    },
    layout: NonAuthLayout,
  },
  impersonateStart: {
    path: appRoutes.impersonateStart,
    lazy: async () => {
      const { StartImpersonatePage } = await import(
        'src/pages/ImpersonatePage/StartImpersonatePage'
      );
      return { Component: StartImpersonatePage };
    },
    layout: NonAuthLayout,
  },
  impersonateStop: {
    path: appRoutes.impersonateStop,
    lazy: async () => {
      const { StopImpersonatePage } = await import('src/pages/ImpersonatePage/StopImpersonatePage');
      return { Component: StopImpersonatePage };
    },
    layout: NonAuthLayout,
  },
  login: {
    path: appRoutes.login,
    lazy: async () => {
      const { Login } = await import('src/routes/UnauthenticatedStack/Login');
      return { Component: Login };
    },
    layout: NonAuthLayout,
    restricted: [restricted.admin, restricted.editor],
  },
  forgotPassword: {
    path: appRoutes.forgotPassword,
    lazy: async () => {
      const { ForgetPasswordPage } = await import('src/routes/UnauthenticatedStack/ForgetPassword');
      return { Component: ForgetPasswordPage };
    },
    layout: NonAuthLayout,
    restricted: [restricted.admin, restricted.editor],
  },
  forgotPasswordReset: {
    path: appRoutes.forgotPasswordReset,
    lazy: async () => {
      const { ForgetPasswordConfirmPage } = await import(
        'src/routes/UnauthenticatedStack/ForgetPasswordConfirm'
      );
      return { Component: ForgetPasswordConfirmPage };
    },
    layout: NonAuthLayout,
    restricted: [restricted.admin, restricted.editor],
  },
  usersList: {
    path: appRoutes.usersList,
    lazy: async () => {
      const { UsersListPage } = await import('src/pages/user/');
      return { Component: UsersListPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest, restricted.editor],
  },
  createUser: {
    path: appRoutes.createUser,
    lazy: async () => {
      const { CreateNewUser } = await import('src/routes/AuthenticatedStack/Users/CreateNewUser');
      return { Component: CreateNewUser };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest, restricted.editor],
  },
  user: {
    path: appRoutes.user,
    lazy: async () => {
      const { User } = await import('src/routes/AuthenticatedStack/Users/User');
      return { Component: User };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest, restricted.editor],
  },
  companiesList: {
    path: appRoutes.companiesList,
    lazy: async () => {
      const { CompaniesListPage } = await import('src/pages/company');
      return { Component: CompaniesListPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  company: {
    path: appRoutes.company,
    lazy: async () => {
      const { Company } = await import('src/routes/AuthenticatedStack/Companies/Company');
      return { Component: Company };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  companyPersonnel: {
    path: appRoutes.companyPersonnel,
    lazy: async () => {
      const { CompanyPersonnelPage } = await import('src/pages/company');
      return { Component: CompanyPersonnelPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  subscriptionsList: {
    path: appRoutes.subscriptionsList,
    lazy: async () => {
      const { SubscriptionsListPage } = await import('src/pages/Subscriptions');
      return { Component: SubscriptionsListPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest, restricted.editor],
  },
  requestsList: {
    path: appRoutes.requestsList,
    lazy: async () => {
      const { RequestsListPage } = await import('src/pages/request');
      return { Component: RequestsListPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  companyRequestsList: {
    path: appRoutes.companyRequestsList,
    lazy: async () => {
      const { CompanyRequestsListPage } = await import('src/pages/company');
      return { Component: CompanyRequestsListPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  userRequestsList: {
    path: appRoutes.userRequestsList,
    lazy: async () => {
      const { UserRequestsListPage } = await import('src/pages/Users');
      return { Component: UserRequestsListPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  request: {
    path: appRoutes.request,
    lazy: async () => {
      const { RequestPage } = await import('src/pages/request');
      return { Component: RequestPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  requestRevisions: {
    path: appRoutes.requestRevisions,
    lazy: async () => {
      const { RevisionsPage } = await import('src/pages/RevisionsPage');
      return { Component: RevisionsPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  companyBrandsList: {
    path: appRoutes.companyBrandsList,
    lazy: async () => {
      const { CompanyBrandsListPage } = await import('src/pages/company');
      return { Component: CompanyBrandsListPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  brand: {
    path: appRoutes.brand,
    lazy: async () => {
      const { Brand } = await import(
        'src/routes/AuthenticatedStack/Companies/Company/Brands/Brand'
      );
      return { Component: Brand };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  profile: {
    path: appRoutes.profile,
    lazy: async () => {
      const { ProfilePage } = await import('src/pages/profile');
      return { Component: ProfilePage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest],
  },
  healthCheck: {
    path: appRoutes.healthCheck,
    lazy: async () => {
      const { HealthCheck } = await import('src/routes/AuthenticatedStack/HealthCheck');
      return { Component: HealthCheck };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest, restricted.editor],
  },
  teamsList: {
    path: appRoutes.teamsList,
    lazy: async () => {
      const { TeamsListPage } = await import('src/pages/Teams');
      return { Component: TeamsListPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest, restricted.editor],
  },
  team: {
    path: appRoutes.team,
    lazy: async () => {
      const { TeamViewPage } = await import('src/pages/Teams');
      return { Component: TeamViewPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest, restricted.editor],
  },
  teamRequests: {
    path: appRoutes.teamRequests,
    lazy: async () => {
      const { TeamRequestsListPage } = await import('src/pages/Teams/TeamRequestsListPage');
      return { Component: TeamRequestsListPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest, restricted.editor],
  },
  socialLogin: {
    path: appRoutes.socialLogin,
    lazy: async () => {
      const { LoginCallbackPage } = await import('src/pages/social/login-callback-page');
      return { Component: LoginCallbackPage };
    },
    layout: NonAuthLayout,
    restricted: [restricted.admin, restricted.editor],
  },
  socialProviderCallback: {
    path: appRoutes.socialProviderCallback,
    lazy: async () => {
      const { AddSocialCallbackPage } = await import('src/pages/social/add-social-callback-page');
      return { Component: AddSocialCallbackPage };
    },
    layout: NonAuthLayout,
    restricted: [restricted.guest],
  },
  urlBuilder: {
    path: appRoutes.urlBuilder,
    lazy: async () => {
      const { UrlBuilderPage } = await import('src/pages/url-builder/url-builder-page');
      return { Component: UrlBuilderPage };
    },
    layout: AuthenticatedLayout,
    restricted: [restricted.guest, restricted.editor],
  },
  airtableRoot: {
    path: airtableRootRoute.path as string,
    lazy: airtableRootRoute.lazy,
    restricted: [restricted.guest, restricted.editor],
    layout: AuthenticatedLayout,
  },
  airtableBase: {
    path: airtableBaseRoute.path as string,
    lazy: airtableBaseRoute.lazy,
    restricted: [restricted.guest, restricted.editor],
    layout: AuthenticatedLayout,
  },
  airtableBaseTable: {
    path: airtableBaseTableRoute.path as string,
    lazy: airtableBaseTableRoute.lazy,
    restricted: [restricted.guest, restricted.editor],
    layout: AuthenticatedLayout,
  },
  any: {
    path: appRoutes.any,
    lazy: async () => {
      const { NotFoundPage } = await import('src/pages/NotFoundPage/NotFoundPage');
      return { Component: NotFoundPage };
    },
    layout: NonAuthLayout,
  },
};

export { routes };
